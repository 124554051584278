import React from "react";
import ReactDOM from "react-dom";
import { Box } from "@material-ui/core";
import App from "./App";
import "./scss/main.css";
import { Provider } from "react-redux";
import { store } from "./Redux/store";

ReactDOM.render(
  <Box>
    <Provider store={store}>
      <App />
    </Provider>
  </Box>,
  document.getElementById("root")
);
