import { applyMiddleware, combineReducers, createStore, compose } from "redux";
// import { LanguageChanger } from "./Auth/reducer";
import { LanguageChanger, changeDashboardLayout } from "./Auth/reducer";
import thunk from "redux-thunk";
const rootReducer = combineReducers({
  auth: LanguageChanger,
  // auth: changeDashboardLayout,
});

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
export const store = createStore(rootReducer, enhancer);
