export default {
  h1: {
    fontWeight: "600",
    color: "#000",
    lineHeight: "1.2",
    fontSize: "26px",
    fontFamily: "system-ui",
  },
  h2: {
    fontSize: "22px",
    lineHeight: "1.2",
    fontWeight: "400",
    color: "#000",
    fontFamily: "system-ui",
  },
  h3: {
    fontSize: "18px",
    lineHeight: "1.2",
    fontWeight: "400",
    color: "#000",
    fontFamily: "system-ui",
  },
  h4: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#000",
    fontFamily: "system-ui",
  },
  h5: {
    fontSize: "22px",
    fontWeight: "500",
    color: "#000",
    fontFamily: "system-ui",
  },
  h6: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
    fontFamily: "system-ui",
  },
  overline: {
    fontSize: "18px",
    fontFamily: "system-ui",
    marginBottom: "15px",
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "justify",
    color: "#999",
  },

  body1: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#000",
    textAlign: "justify",
    fontFamily: "system-ui",
  },
};

// const typography = (baseFontSize) => ({
//   h1: {
//     fontSize: `${26 / baseFontSize}rem`,
//     fontWeight: "600",
//     lineHeight: "1.2",
//     color: "#000",
//     fontFamily: "system-ui",
//   },
//   h2: {
//     fontSize: `${22 / baseFontSize}rem`,
//     fontWeight: "400",
//     lineHeight: "1.2",
//     color: "#000",
//     fontFamily: "system-ui",
//   },
//   h3: {
//     fontSize: `${18 / baseFontSize}rem`,
//     fontWeight: "400",
//     lineHeight: "1.2",
//     color: "#000",
//     fontFamily: "system-ui",
//   },
//   h4: {
//     fontSize: `${20 / baseFontSize}rem`,
//     fontWeight: "600",
//     color: "#000",
//     fontFamily: "system-ui",
//   },
//   h5: {
//     fontSize: `${18 / baseFontSize}rem`,
//     fontWeight: "500",
//     color: "#000",
//     fontFamily: "system-ui",
//   },
//   h6: {
//     fontSize: `${16 / baseFontSize}rem`,
//     fontWeight: "400",
//     color: "#000",
//     fontFamily: "system-ui",
//   },
//   overline: {
//     fontSize: `${18 / baseFontSize}rem`,
//     fontWeight: "bold",
//     textTransform: "uppercase",
//     color: "#999",
//     marginBottom: "15px",
//     textAlign: "justify",
//     fontFamily: "system-ui",
//   },
//   body1: {
//     fontSize: `${16 / baseFontSize}rem`,
//     fontWeight: "500",
//     color: "#000",
//     textAlign: "justify",
//     fontFamily: "system-ui",
//   },

//   body2: {
//     fontSize: `${16 / baseFontSize}rem`,
//     fontWeight: "500",
//     color: "#000",
//     textAlign: "justify",
//     fontFamily: "system-ui",
//   },
//   link: {
//     fontSize: `${16 / baseFontSize}rem`,
//     fontWeight: "500",
//     color: "#0192be",
//     textDecoration: "none",
//     fontFamily: "system-ui",
//     "&:hover": {
//       textDecoration: "underline",
//     },
//   },
// });

// export default typography;
