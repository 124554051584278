import { SET_LANG, LAYOUT_CHANGE } from "../Auth/actionType";
const initState = {
  changeDashboard: "true",
  langCode: window.localStorage.getItem("languageId")
    ? window.localStorage.getItem("languageId")
    : "en",
};

export const LanguageChanger = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_LANG:
      return {
        ...state,
        langCode: payload.langCode,
      };
    default:
      return state;
  }
};
export const changeDashboardLayout = (state = initState, { type, payload }) => {
  switch (type) {
    case LAYOUT_CHANGE:
      return {
        ...state,
        changeDashboard: payload.changeDashboard,
      };
    default:
      return state;
  }
};
