import React from "react";
import { matchPath } from "react-router-dom";
// import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Box, Drawer, List, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import NavItem from "./NavItem";
import Sidebar from "../../../../componentkms/Sidebar";

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean()}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    top: "109px",
    left: "0",
    height: "calc(100% - 80px)",
    paddingBottom: "40px",
    "@media(min-width:1480px)": {
      width: 330,
    },
    "@media(max-width:1480px)": {
      width: 303,
    },
    "@media(max-width:1280px)": {
      width: 309,
    },

    "@media(max-width:1024px)": {
      display: "none",
    },
  },

  logo: {
    width: "40px",
    paddingLeft: "20px",
    paddingTop: "21px",
    cursor: "pointer",
  },

  mainBox: {
    // padding: "0 10px 0 10px",
    // borderBottom: "3px solid #8080802e",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const history = useHistory();

  const content = (
    <Box
      className="sidebarscrollbar"
      id="sidebarscrollbar"
      style={{
        borderTop: "1px solid #d6952c",
      }}
    >
      <Sidebar />
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        <img
          src="images/logo/Logo New with Name-ENG_final.png"
          alt="logo"
          className={classes.logo}
          onClick={() => history.push("/")}
          style={{ cursor: "pointer" }}
        />
        {content}
      </Drawer>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        variant="persistent"
        open
      >
        {content}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
