import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  SvgIcon,
  Box,
  Typography,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { TopBarData } from "./TopBarData";
import TopbarTop from "../../../../componentkms/TopbarTop";
import MobileDrawer from "../../../../componentkms/Mobile drawer/MobileDrawer";
import HomeIcon from "@material-ui/icons/Home";
import { useHistory, NavLink } from "react-router-dom";
import { localeFunction } from "../../../../utilskms";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.paper,
  },
  toolbar: {
    padding: "initial",
    backdropFilter: "blur(44px)",
    background: "#FFF",
    height: "60px",
    "@media(max-width:1024px)": {
      background: "#d6952c",
    },
  },
  menuIconBtn: {
    display: "none",
    "@media(max-width:1024px)": {
      display: "block",
      width: "100%",
    },
  },

  topbardata: {
    width: "100%",
    "@media(max-width:1024px)": {
      display: "none",
    },
  },
  ThreeDotsIcon: {
    display: "none",
    // height: "100%",

    "@media(max-width:1024px)": {
      display: "block",
    },
  },
  homeWithMenu: {
    display: "flex",
    alignItems: "center",
  },
  menuLinks: {
    display: "flex",
    alignItems: "center",
    padding: "0px 50px",
    "@media(max-width:1440px)": {
      padding: "0px 12px",
    },
    "@media(max-width:1280px)": {
      padding: "0px 17px",
    },
    "@media(max-width:1275px)": {
      padding: "0px 2px",
    },
    "& a": { textDecoration: "none" },
    "& .menulist": {
      "& h6": {
        fontSize: "16px",
        fontWeight: 500,
        color: "#FFF",
        textTransform: "captalize",
        whiteSpace: "pre",
        transition: "0.5s ease-in-out",
      },
      "& :hover": {
        color: "#000 !important",
        transition: "0.5s ease-in-out",
      },
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openSidebar, setSidebar] = useState(false);
  const [type, setType] = useState("");
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar
        className={clsx(classes.root, className)}
        color="default"
        {...rest}
      >
        <TopbarTop />
        <Toolbar className={classes.toolbar}>
          <Box className={classes.menuIconBtn}>
            <Box className={classes.homeWithMenu}>
              <IconButton
                color="inherit"
                onClick={handleDrawerOpen}
                style={{ marginRight: 10 }}
              >
                <SvgIcon style={{ fontSize: "40px" }}>
                  <MenuIcon style={{ color: "#fff" }} />
                </SvgIcon>
              </IconButton>
              <Box className={classes.menuLinks}>
                <HomeIcon
                  style={{
                    color: "#FFF",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    history.push("/kms-portal");
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                />
              </Box>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Box className={classes.menuLinks}>
                <NavLink to="/">
                  <Box
                    className="menulist"
                    style={{
                      background: "#8c5f09",
                      padding: "5px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="h6">
                      {localeFunction("internal_Portal")}
                    </Typography>
                  </Box>
                </NavLink>
              </Box>
            </Box>
          </Box>
          <Box className={classes.topbardata}>
            <TopBarData />
          </Box>
        </Toolbar>
      </AppBar>
      <MobileDrawer
        open={open}
        CloseSiderbarMenu={handleDrawerClose}
        openSidebar={openSidebar}
        type={type}
        setOpen={setOpen}
      />
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
