import React, { useContext } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { localeFunction } from "../../../utils";
import { AuthContext } from "../../../context/Auth";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    zIndex: 999,
    padding: "10px 0px",
    color: "#FFF",
    background: "#9a6808",
    width: "100%",
    textAlign: "center",
    "@media(max-width:1024px)": {
      width: "100%",
    },
    "@media(max-width:768px)": {
      display: "block",
      textAlign: "center",
    },
    "@media(max-width:425px)": {
      display: "block",
      textAlign: "center",
    },

    "& h6": {
      color: "#FFFF",
      paddingLeft: "265px",
      "@media(max-width:1024px)": {
        paddingLeft: "initial",
      },
      "@media(max-width:768px)": {
        paddingLeft: "initial",
      },
      "@media(max-width:425px)": {
        paddingLeft: "initial",
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <>
      <Box className={classes.footerSection}>
        <Typography variant="h6">{localeFunction("companyname")}</Typography>
      </Box>
    </>
  );
}
