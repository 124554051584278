import React from "react";
import { Drawer, Box, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Sidebar from "../Sidebar";
import ProfileImg from "../../component/ProfileIamge";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    right: 0,
    background: "#8c5f09",
  },
  mainMobilemenu: {
    padding: "20px 0px 0px 10px",
    background: "#fff",
    color: "#000",
    "@media(max-width:615px)": {
      padding: "10px 0px 0px 10px",
    },
  },

  menuLinks: {
    display: "flex",
    alignItems: "center",
    padding: "0px 50px",
    "@media(max-width:1440px)": {
      padding: "0px 12px",
    },
    "@media(max-width:1280px)": {
      padding: "0px 13px",
    },
    "@media(max-width:1275px)": {
      padding: "0px 2px",
      borderBottom: "1px solid #ffffff69",
    },

    "& .menuComponent": {
      display: "none",
      zIndex: 1,
      transition: "0.5s ease-in-out",
    },
    "&:hover": {
      "& .menuComponent": {
        display: "block",
        position: "absolute",
        zIndex: 999,
        background: "#fff",
        width: "87%",
        borderBottom: "2px solid #d6952c",
        "@media(max-width:425px)": {
          position: "fixed",
          zIndex: 999,
          width: "55%",
        },
      },
    },

    "& .profilebox": {
      display: "flex",
      gap: "10px",
      paddingBottom: "15px",
      marginLeft: "3px",
    },
  },
  menulist: {
    "& h6": {
      color: "#FFF",
      fontSize: "14px",
      textTransform: "capitalize",
    },
  },
  mandatesCardWrapper: {
    // paddingBottom: "5px",
    "& .mandatesTitles": {
      fontWeight: 500,
      padding: "10px 0px 10px 10px",
      fontFamily: "system-ui",
      color: "#000",

      "&:hover": {
        background: "#e4a70a78",
        cursor: "pointer",
      },
    },
  },
  devider: {
    borderBottom: "1px solid #fff",
  },
}));

const MobileDrawer = ({ open, CloseSiderbarMenu, setOpen }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Drawer
      className={classes.drawer}
      {...{
        anchor: "left",
        open: open,
        onClose: CloseSiderbarMenu,
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {/* <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box style={{ paddingLeft: "16px" }} mt={2}>
          <Box
            style={{ maxWidth: "130px" }}
            onClick={() => history.push("/kms-portal")}
          >
            <img
              src="../images/logo/Logo New with Name-ENG.png"
              alt="Logo"
              width={"100%"}
            />
          </Box>
        </Box>
        <Box align="end">
          <IconButton onClick={CloseSiderbarMenu}>
            <CloseIcon style={{ color: "#FFF" }} />
          </IconButton>
        </Box>
      </Box> */}
      {/* <Box className={classes.devider} mt={3}>
        <Divider />
      </Box> */}
      <Box className={classes.mainMobilemenu}>
        <Box className={classes.menuLinks}>
          <Box className="profilebox">
            <ProfileImg />
          </Box>
        </Box>

        {/* <Box style={{ color: "#FFF" }}>
          <Box className={classes.menuLinks}>
            <Box className="MenuTab">
              <NavLink to="/my-task">
                <Button className={classes.menulist}>
                  <Typography variant="h6">
                    {localeFunction("My_Tasks")}
                  </Typography>
                </Button>
              </NavLink>
            </Box>
          </Box>

          <Box className={classes.menuLinks}>
            <Box className="MenuTab">
              <NavLink to="/contact">
                <Button className={classes.menulist}>
                  <Typography variant="h6">
                    {localeFunction("Contacts")}
                  </Typography>
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Box> */}
      </Box>

      <Box p={"2px 0 32px 0"} className="scrollbar" id="scrollbar">
        <Sidebar setOpen={setOpen} open={open} />
      </Box>
    </Drawer>
  );
};

export default MobileDrawer;
