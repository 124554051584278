import React, { lazy } from "react";
import HomeLayout from "./layouts/HomeLayout";
import Loginlayout from "./layouts/loginLayout/index.js";
import DashboardLayoutKMS from "./layouts/HomeLayout/DashboardLayoutkms";
import { Redirect } from "react-router-dom";

export const routesKms = [
  {
    exact: true,
    path: "/login",
    layout: Loginlayout,
    component: lazy(() => import("../src/view/pages/login/Login.js")),
  },

  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("../src/view/pages/home")),
  },
  {
    exact: true,
    path: "/whistle-blower-complaint-form",
    layout: HomeLayout,
    component: lazy(() =>
      import("./component/WistleBlowerMech/WistleBlowerForm.js")
    ),
  },

  {
    exact: true,
    path: "/complain-table",
    layout: HomeLayout,
    component: lazy(() =>
      import("./component/WistleBlowerMech/ComplainTable.js")
    ),
  },

  {
    exact: true,
    path: "/search-complaint",
    layout: HomeLayout,
    component: lazy(() =>
      import("./component/WistleBlowerMech/SearchCompiant.js")
    ),
  },

  {
    exact: true,
    path: "/forward-complain-table",
    layout: HomeLayout,
    component: lazy(() =>
      import("./component/WistleBlowerMech/ForwardedComplainTable.js")
    ),
  },

  {
    exact: true,
    path: "/generated signature",
    layout: HomeLayout,
    component: lazy(() => import("./component/GenerateSign/MainGenSign.js")),
  },
  {
    exact: true,
    path: "/kms-portal",
    layout: DashboardLayoutKMS,
    component: lazy(() => import("./view/pageskms/Home")),
  },

  {
    exact: true,
    path: "/domain-repository",
    layout: DashboardLayoutKMS,
    component: lazy(() =>
      import("./componentkms/Domain repository/MainDomainRepository.js")
    ),
  },

  {
    exact: true,
    path: "/department-repository",
    layout: DashboardLayoutKMS,
    component: lazy(() =>
      import("./componentkms/Departmental Repository/MainDepartmentFile.js")
    ),
  },

  {
    exact: true,
    path: "/institutional-repository",
    layout: DashboardLayoutKMS,
    component: lazy(() =>
      import(
        "./componentkms/Institutional Repository/InstitutionalRepository.js"
      )
    ),
  },
  {
    exact: true,
    path: "/table",
    layout: DashboardLayoutKMS,
    component: lazy(() =>
      import("./componentkms/Domain repository/DomainResTable.js")
    ),
  },

  {
    exact: true,
    path: "/institutional-repository-table",
    layout: DashboardLayoutKMS,
    component: lazy(() =>
      import("./componentkms/Institutional Repository/InstitutionalTable.js")
    ),
  },

  {
    exact: true,
    path: "/department-doc-list",
    layout: DashboardLayoutKMS,
    component: lazy(() =>
      import("./view/pageskms/Home/shard document view/DocumentList.js")
    ),
  },
  {
    exact: true,
    path: "/my-task",
    layout: DashboardLayoutKMS,
    component: lazy(() => import("./componentkms/my task/MyTask.js")),
  },

  {
    exact: true,
    path: "/learning",
    layout: DashboardLayoutKMS,
    component: lazy(() => import("./componentkms/Learning/Learning.js")),
  },

  {
    exact: true,
    path: "/other-website-links",
    layout: DashboardLayoutKMS,
    component: lazy(() =>
      import("./componentkms/Other website link/OtherWebsiteLinks.js")
    ),
  },

  {
    exact: true,
    path: "/other-document-repository",
    layout: DashboardLayoutKMS,
    component: lazy(() =>
      import("./componentkms/other document repo/OtherDocument.js")
    ),
  },

  {
    exact: true,
    path: "/contact",
    layout: DashboardLayoutKMS,
    component: lazy(() => import("./componentkms/contact/Contact.js")),
  },

  {
    exact: true,
    path: "/frequently-and-asked-questions",
    layout: DashboardLayoutKMS,
    component: lazy(() => import("./componentkms/other document repo/Faq.js")),
  },

  {
    exact: true,
    path: "/upload",
    layout: DashboardLayoutKMS,
    component: lazy(() => import("./componentkms/UploadForm.js")),
  },
  {
    exact: true,
    path: "/share-document-table",
    layout: DashboardLayoutKMS,
    component: lazy(() => import("./componentkms/ShareDocumentTable.js")),
  },

  {
    exact: true,
    path: "/404",
    layout: HomeLayout,
    component: lazy(() => import("./view/errors/NotFound.js")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
