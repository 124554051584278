import {
  SET_LANG,
  LAYOUT_CHANGE,
  REFRESH_CHILD_COMPONENTS,
} from "../Auth/actionType";

export function refreshChildComponentsAction() {
  return {
    type: REFRESH_CHILD_COMPONENTS,
  };
}

export const changeLanguage = (payload) => {
  return {
    type: SET_LANG,
    payload,
  };
};
export const changeDashboard = (payload) => {
  return {
    type: LAYOUT_CHANGE,
    payload,
  };
};
