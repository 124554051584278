import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  bannerImg: {
    height: "200px",
    width: "100%",
    "@media(max-width:1480px)": {
      height: "170px",
    },
    "@media(max-width:1280px)": {
      height: "170px",
      marginTop: "2px",
    },
    "@media(max-width:1024px)": {
      height: "170px",
      marginTop: "initial",
    },
    "@media(max-width:768px)": {
      height: "140px",
      marginTop: "initial",
    },
    "@media(max-width:599px)": {
      height: "130px",
      marginTop: "initial",
    },
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
}));

const Banner = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.bannerImg}>
        <img
          src="images/Small Banner/300X1800-KMS-Banner-1.png"
          alt="banner img"
        />
      </Box>
    </>
  );
};

export default Banner;
