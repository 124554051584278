import { Avatar } from "@material-ui/core";
// import { AvTimer } from "@material-ui/icons";
import React from "react";

const ProfileImg = ({ image }) => {
  return (
    <Avatar
      alt="image"
      src={image ? image : ""}
      style={{
        borderRadius: "100%",
        width: "100%",
        height: "100%",
        background: "rgba(255,255,255,0.9)",
      }}
    />
  );
};

export default ProfileImg;
