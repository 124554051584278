import React, { useContext } from "react";
import { makeStyles, Box, IconButton, Container } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AuthContext } from "../context/Auth";
import BackDropSpinner from "../componentkms/BackDropSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: "30px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  underlineText: {
    // width: "100%",
    "@media(max-width:1280px)": {
      fontSize: "14px",
    },
    color: "#000",
    textDecoration: "none",
    transition: "0.5s ease-in-out",
    "& :hover": {
      textDecoration: "underline",
      color: "#0192be",
    },
  },
  annoucementText: {
    cursor: "auto !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    "& button": {
      width: "35px",
      height: "35px",
      color: "#FFF",
      background: "#06485b",
    },
    "& h3": {
      lineHeight: "23px",
      fontWeight: 400,
      fontFamily: "system-ui",
      "@media(max-width:1280px)": {
        fontSize: "14px",
      },
    },
  },
  acconceText: {
    margin: "0px 0px 0px 68px",
    display: "flex",
    alignItems: "center",
    "& h3": {
      lineHeight: "23px",
      fontWeight: 400,
      fontFamily: "system-ui",
      "@media(max-width:1280px)": {
        fontSize: "14px !important",
      },
    },
  },
}));

export default function Allannoucement({
  annoucement,
  setPage,
  page,
  _count,
  page1,
  setPage1,
  _count1,
  searchQuery,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const auth = useContext(AuthContext);
  const waterMrkFunctionApi = auth?.addWaterMarkFunc;
  const spinnerLoader = auth?.isLoading;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const theme = createMuiTheme({
    overrides: {
      MuiTabs: {
        flexContainer: {
          justifyContent: "start !important",
        },
      },
      MuiPaginationItem: {
        page: {
          "&.Mui-selected": {
            color: "#fff",
            backgroundColor: "#0192be",
          },
        },
      },
      MuiAccordionSummary: {
        content: {
          display: "initial",
          margin: "0px 0px !important",
          padding: "5px 0px !important",
        },
      },
      MuiTypography: {
        body1: {
          fontSize: "16px",
          fontWeight: "500",
          color: "#000",
          // textAlign: "justify",
          fontFamily: "system-ui",
        },
        h3: {
          fontFamily: "system-ui",
          fontSize: "16px",
          lineHeight: "40px",
          fontWeight: "500",
        },
      },
      MuiAccordionDetails: {
        root: {
          display: "initial",
          padding: "0 0 10px 0",
        },
      },
      MuiAccordion: {
        root: {
          "&::before": {
            background: "none !important",
          },
        },
      },
    },
  });

  return (
    <div className={classes.root}>
      {spinnerLoader ? (
        <BackDropSpinner spinnerLoader={spinnerLoader} />
      ) : (
        <Container>
          <ThemeProvider theme={theme}>
            {searchQuery !== "" ? (
              <Box>
                {annoucement &&
                  annoucement?.map((data, index) => {
                    if (data?.status === "0") {
                      return (
                        <>
                          <Box
                            style={{
                              borderRadius: "0px",
                              padding: "4px 16px",
                              boxShadow: "none",
                              marginTop: "5px",
                            }}
                            elevation={0}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              style={{ cursor: "auto !important" }}
                            >
                              <Box className={classes.underlineText}>
                                <Box className={classes.annoucementText}>
                                  <IconButton>
                                    <LibraryBooksIcon
                                      style={{ fontSize: "18px" }}
                                    />
                                  </IconButton>

                                  <Typography
                                    variant="h3"
                                    onClick={() =>
                                      waterMrkFunctionApi("", data?.id)
                                    }
                                  >
                                    {data?.title}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Accordion
                            expanded={expanded === index}
                            onChange={handleChange(index)}
                            style={{
                              background: "#FFF",
                              boxShadow: "none",
                            }}
                          >
                            <AccordionSummary
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                width={"100%"}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  style={{ cursor: "auto !important" }}
                                >
                                  <Box className={classes.underlineText}>
                                    <Box
                                      style={{
                                        cursor: "auto !important",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "15px",
                                      }}
                                    >
                                      <IconButton
                                        style={{
                                          width: "35px",
                                          height: "35px",
                                          color: "#FFF",
                                          background: "#06485b",
                                        }}
                                      >
                                        <LibraryBooksIcon
                                          style={{ fontSize: "18px" }}
                                        />
                                      </IconButton>

                                      <Typography
                                        variant="h3"
                                        style={{
                                          lineHeight: "23px",
                                          fontWeight: 400,
                                          fontFamily: "system-ui",
                                          cursor: "pointer",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {data?.title}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <IconButton>
                                  <ExpandMoreIcon />
                                </IconButton>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              {data &&
                                data?.list?.map((items, index) => {
                                  return (
                                    <Box
                                      display={"flex"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                      style={{ cursor: "auto !important" }}
                                      key={index}
                                    >
                                      <Box>
                                        <Box className={classes.underlineText}>
                                          <Box
                                            p={"0 75px 10px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                          >
                                            <Typography
                                              variant="h3"
                                              style={{
                                                lineHeight: "23px",
                                                fontWeight: 400,
                                                fontFamily: "system-ui",
                                                cursor: "pointer",
                                                fontSize: "14px",
                                              }}
                                              onClick={() =>
                                                waterMrkFunctionApi(
                                                  "",
                                                  items?.id
                                                )
                                              }
                                            >
                                              {items?.title}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        {/* </a> */}
                                      </Box>
                                    </Box>
                                  );
                                })}
                            </AccordionDetails>
                          </Accordion>
                        </>
                      );
                    }
                  })}
                {annoucement?.length > 0 && (
                  <Box display={"flex"} justifyContent={"center"} mt={6}>
                    <Pagination
                      count={_count}
                      page={page}
                      onChange={(e, v) => setPage(v)}
                    />
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                {annoucement &&
                  annoucement?.map((data, index) => {
                    if (data?.status == "0") {
                      return (
                        <>
                          <Box
                            style={{
                              borderRadius: "0px",
                              padding: "4px 16px",
                              boxShadow: "none",
                              marginTop: "5px",
                            }}
                            elevation={0}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              style={{ cursor: "auto !important" }}
                            >
                              <Box className={classes.underlineText}>
                                <Box className={classes.annoucementText}>
                                  <IconButton>
                                    <LibraryBooksIcon
                                      style={{ fontSize: "18px" }}
                                    />
                                  </IconButton>

                                  <Typography
                                    variant="h3"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      waterMrkFunctionApi("", data?.id)
                                    }
                                  >
                                    {data?.title}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Accordion
                            expanded={expanded === index}
                            onChange={handleChange(index)}
                            style={{
                              background: "#FFF",
                              boxShadow: "none",
                            }}
                            key={index}
                          >
                            <AccordionSummary
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Box
                                width={"100%"}
                                display={"flex"}
                                alignItems={"center"}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  style={{ cursor: "auto !important" }}
                                >
                                  <Box className={classes.underlineText}>
                                    <Box className={classes.annoucementText}>
                                      <IconButton>
                                        <LibraryBooksIcon
                                          style={{ fontSize: "18px" }}
                                        />
                                      </IconButton>

                                      <Typography
                                        variant="h3"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {data?.title}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <IconButton>
                                  <ExpandMoreIcon />
                                </IconButton>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              {data &&
                                data?.list?.map((items, index) => {
                                  return (
                                    <Box
                                      display={"flex"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                      style={{ cursor: "auto !important" }}
                                      key={index}
                                    >
                                      <Box>
                                        <Box className={classes.underlineText}>
                                          <Box className={classes.acconceText}>
                                            <Box
                                              style={{
                                                width: "6px",
                                                height: "6px",
                                                backgroundColor: "black",
                                                borderRadius: "50%",
                                              }}
                                            ></Box>
                                            <Typography
                                              variant="h3"
                                              style={{
                                                cursor: "pointer",
                                                padding: "0px 0px 0px 16px",
                                              }}
                                              onClick={() =>
                                                waterMrkFunctionApi(
                                                  "",
                                                  items?.id
                                                )
                                              }
                                            >
                                              {items?.title}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  );
                                })}
                            </AccordionDetails>
                          </Accordion>
                        </>
                      );
                    }
                  })}
                {annoucement?.length > 0 && (
                  <Box display={"flex"} justifyContent={"center"} mt={6}>
                    <Pagination
                      count={_count1}
                      page={page1}
                      onChange={(e, v) => setPage1(v)}
                    />
                  </Box>
                )}
              </Box>
            )}
          </ThemeProvider>
        </Container>
      )}
    </div>
  );
}
