import React, { useContext } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { localeFunction } from "../../utils";
import { AuthContext } from "../../context/Auth";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px",
    color: "#FFF",
    background: "rgb(35, 38, 47)",
    width: "100%",
    "@media(max-width:425px)": {
      display: "block",
      textAlign: "center",
    },
    "@media(max-width:768px)": {
      display: "block",
      textAlign: "center",
    },
    "& h6": {
      color: "#FFFF",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <>
      <Box className={classes.footerSection}>
        <Typography variant="h6"> {localeFunction("companyname")}</Typography>
      </Box>
    </>
  );
}
