import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "./TopBar";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    position: "relative",
    // height: "100vh",

    "@media(max-width:1280px)": {
      height: "auto !important",
    },
  },
  MainLayout: {
    // height: "100vh",
    overflowX: "hidden",
    paddingTop: "163px !important",
    zIndex: 999,
    "@media(max-width:1280px)": {
      paddingTop: "120px !important",
    },
  },

  footerSec: {
    position: "absolute",
    // bottom: "0px",
    left: "0px",
    right: "0px",
    zIndex: 999,
    "@media(max-width:615px)": {
      position: "relative",
    },
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>
      <div className={classes.MainLayout}>{children}</div>
      <Box className={classes.footerSec}>
        <Footer />
      </Box>
    </div>
  );
};

export default MainLayout;
