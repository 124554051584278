import React, { useContext, useEffect } from "react";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { MenuItem, FormControl, Select } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../Reduxkms/Auth/action";
import { AuthContext } from "../context/Auth";

import { refreshChildComponentsAction } from "../Redux/Auth/action";

const theme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: "2px solid #fff", // Change the underline to a green line after focus
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "none !important",
        },
      },
    },
    MuiSelect: {
      icon: {
        color: "#fff",
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 70,
    // padding: "0 50px",
  },
  selectEmpty: {
    marginTop: "0px",
    color: "#fff",
  },
}));

export default function LanguageComponant() {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  const dispatch = useDispatch();
  const handleChange = (event) => {
    dispatch(refreshChildComponentsAction());
    auth?.setLanguage(event.target.value);
    // window.location.reload();
  };
  useEffect(() => {
    if (window.localStorage.getItem("languageId") == null) {
      dispatch(changeLanguage({ langCode: auth._language }));
    }
  }, [window.localStorage.getItem("languageId") == null]);
  const menuProps = {
    getContentAnchorEl: null,
    disableScrollLock: true,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };
  return (
    <div style={{ display: "flex" }}>
      <ThemeProvider theme={theme}>
        <FormControl className={classes.formControl}>
          <Select
            labelId="select-label"
            value={auth._language}
            onChange={(e) => {
              handleChange(e);
              dispatch(changeLanguage({ langCode: e.target.value }));
              window.localStorage.setItem("languageId", e.target.value);
            }}
            className={classes.selectEmpty}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={menuProps}
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"hi"}>हिंदी</MenuItem>
          </Select>
        </FormControl>
      </ThemeProvider>
    </div>
  );
}
