import React, { useContext } from "react";
import { makeStyles, Box } from "@material-ui/core";
import LanguageComponent from "../component/LanguageComponant";
import EmailIcon from "@material-ui/icons/Email";
import { localeFunction } from "../utilskms";
import CallIcon from "@material-ui/icons/Call";
import { AuthContext } from "../context/Auth";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    height: "45px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 40px 0px 24px",
    "@media (max-width: 1440px)": {
      padding: "0px 30px 0px 24px",
    },
    "@media (max-width: 1280px)": {
      padding: "0px 13px 0px 13px",
    },
    "@media (max-width: 768px)": {
      padding: "0px 10px 0px 11px",
    },
    "@media (max-width: 425px)": {
      height: "auto",
    },
    "& svg": {
      fontSize: "18px",
    },
    "& a": {
      color: "#fff",
      textDecoration: "none",
      fontFamily: "system-ui",
      fontSize: "16px",
      fontWeight: "400",
      "@media (max-width: 576px)": {
        fontSize: "13px",
      },
    },
  },
  ultag: {
    listStyleType: "none",
    marginblockstart: "0em",
    marginblockend: "0em",
    display: "flex",
    gap: "10px",
    padding: "0",
    "@media (max-width: 576px)": {
      display: "block",
    },
  },
  listTag: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    marginblockstart: "0em",
    marginblockend: "0em",
    cursor: "pointer",
    "@media(max-width:576px)": {
      lineHeight: "0px !important",
      marginBottom: "-17px",
    },
  },
  homeText: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 768px)": {
      "& a": {
        fontSize: "12px",
        lineHeight: "19px",
      },
    },
    "@media (max-width: 576px)": {
      fontSize: "10px",
      lineHeight: "20px",
    },
    "&:hover": {
      color: "rgb(255, 205, 30)",
    },
  },

  topbackground: {
    backgroundColor: "#483511",
    height: "inherit",
    // "@media(max-width:576px)": {
    //   padding: "4px 0px",
    // },

    "& .topContainer": {
      "@media(max-width:375px)": {
        paddingRight: "initial !important",
        paddingTop: "5px",
      },
      "@media(max-width:425px)": {
        paddingRight: "initial !important",
      },
    },
  },
  lefttopbartext: {
    display: "flex",
    alignItems: "center",
    color: "#f4e757",
    "@media(max-width:425px)": {
      display: "block",
      lineHeight: "0px !important",
    },
    "@media(max-width:375px)": {
      display: "block",
    },
    "@media(max-width:320px)": {
      display: "block",
    },
    "@media(max-width:768px)": {
      display: "block",
      lineHeight: "0px",
    },
  },
  spantext: {
    paddingLeft: "20px",
    "@media(max-width:768px)": {
      display: "none",
    },
  },
}));
const TopbarTop = () => {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <Box className={classes.topbackground} width={"100%"}>
      <Box className={classes.mainBox}>
        <Box display={"flex"} alignItems={"center"}>
          <Box className={classes.lefttopbartext}>
            <p className={classes.homeText}>
              &nbsp; <a>{localeFunction("IT_Helpdesk")} :</a>
              <a>
                &nbsp;{" "}
                <CallIcon style={{ fontSize: "15px", color: "#f4e757" }} />
                011-24600-668
              </a>
            </p>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <p className={classes.homeText}>
              <EmailIcon />
              &nbsp;
              <a href="mailto:aicho@aicofindia.com">
                {localeFunction("email")}
              </a>
            </p>
          </Box>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Box>
            <LanguageComponent />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TopbarTop;
