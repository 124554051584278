import React, { useContext } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "./TopBar";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    position: "relative",
    // height: "100vh",
    "@media(max-width:1280px)": {
      height: "auto !important",
    },
  },
  MainLayout: {
    // minHeight: "calc(100vh - 415px)",
    height: "100vh",
    // paddingTop: "30px !important",
    overflowX: "hidden",
    // "@media(max-width:2560px)": {
    //   paddingTop: "58px !important",
    // },
    // "@media(max-width:1440px)": {
    //   paddingTop: "58px !important",
    // },
    // "@media(max-width:1280px)": {
    //   paddingTop: "67px !important",
    // },

    // "@media(max-width:375px)": {
    //   paddingTop: "180px !important",
    // },
    // "@media(max-width:768px)": {
    //   paddingTop: "167px !important",
    // },
    // "@media(max-width:425px)": {
    //   paddingTop: "240px !important",
    // },
  },

  footerSec: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    right: "0px",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      {/* <TopBar /> */}
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>
      <div className={classes.MainLayout}>{children}</div>
      <Box className={classes.footerSec}>{/* <Footer /> */}</Box>
    </div>
  );
};

export default MainLayout;
