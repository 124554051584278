import axios from "axios";
import CryptoJS from "crypto-js";
import moment from "moment";
import { store } from "../Redux/store";
import Apiconfigs from "../ApiConfig/Apiconfig";

export const base64Key = "w2IKah2csuNE77fv1Zd8+jzxcJ2GYh3WO5UZMqfHj3Y=";
export const WebsiteLinks =
  "http://sarus-elb-345957920.ap-south-1.elb.amazonaws.com:8080/";

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 80)}...`;
  return sortAdd;
}
export const map = {
  // googleMapKey: "AIzaSyBpWyPnAG_3CFQC3NU0hJPt4r_IBhpsPEA",
  googleMapKey: "",
};

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

// const csrftoken = getCookie("csrftoken");

export const decryptData = (encryptedData) => {
  const key = CryptoJS.enc.Base64.parse(base64Key);
  const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
  const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
  const ciphertext = CryptoJS.lib.WordArray.create(dataBytes.words.slice(4));
  const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
  const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
  return JSON.parse(decryptedText);
};

export const encryptData = (data) => {
  const key = CryptoJS.enc.Base64.parse(base64Key);
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv });

  const ciphertext = iv.concat(encrypted.ciphertext);
  return ciphertext.toString(CryptoJS.enc.Base64);
};

export const getAPIdata = async ({ endPoint }) => {
  try {
    let res;
    res = await axios({
      url: endPoint,
      method: "GET",
    });
    if (res?.status === 200) {
      return decryptData(res?.data);
    } else if (res?.data?.status === 400) {
      return false;
    } else if (res?.data?.status === 401) {
      return false;
    } else if (res?.data?.status === 500) {
      return false;
    } else {
      return false;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      return error;
    } else if (error?.response?.status === 401) {
      return error;
    } else if (error?.response?.status === 500) {
      return error;
    } else {
      return error;
    }
  }
};

export const putAPIdata = async ({ params, data, endPoint, headers }) => {
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "PUT",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          return false;
        }
        if (res?.data?.status === 401) {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

const csrf_token_Generate = async (token) => {
  try {
    const res = await getAPIdata({
      endPoint: Apiconfigs.csrf_token,
    });
    if (res) {
      return res?.csrfToken;
    }
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

export const PostApiFunction = async ({
  endPoint,
  data,
  headers = {},
  withCredentials = false,
}) => {
  const token = await csrf_token_Generate();

  try {
    const res = await axios({
      url: endPoint,
      method: "POST",
      data: data,
      headers: {
        "X-CSRFToken": token,
      },
      withCredentials: true,
      mode: "same-origin",
    });

    if (res?.status === 200) {
      return decryptData(res?.data);
    } else if (
      res?.status === 400 ||
      res?.status === 401 ||
      res?.status === 500
    ) {
      return false;
    } else {
      return false;
    }
  } catch (error) {
    if (
      error?.response?.status === 400 ||
      error?.response?.status === 401 ||
      error?.response?.status === 500
    ) {
      return error;
    } else {
      return error;
    }
  }
};

export const convertDateTime = (val) => {
  var tempDate = new Date(val);
  const toDateFormat = moment(tempDate).format("DD-MMM-yyyy hh:mm a");
  return toDateFormat;
};

export const localeFunction = (key) => {
  let str = store.getState("auth");
  let langCode = str.auth.langCode;

  switch (langCode) {
    case "en":
      return require(`../utils/lang/en`)[key];
    default:
      return require(`../utils/lang/hi`)[key];
  }
};
