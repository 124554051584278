import { SET_LANG, LAYOUT_CHANGE } from "../Auth/actionType";

export const changeLanguage = (payload) => {
  return {
    type: SET_LANG,
    payload,
  };
};
export const changeDashboard = (payload) => {
  return {
    type: LAYOUT_CHANGE,
    payload,
  };
};
