const environments = {
  LOCAL: "http://192.168.0.142:8000/ip_cms",
  UAT: "https://myaic-uat.aicofindia.com/ip_cms",
  LIVE: "https://myaic.aicofindia.com/ip_cms",
  TEST: "http://10.15.22.49:9000/ip_cms",
  STAGING: "https://intstaging.aicofindia.com/ip_cms",
};

const currentEnvironment = environments.LIVE;

let admin = `${currentEnvironment}`;
const Apiconfigs = {
  upload_emp_image: `${admin}/upload_emp_image`,
  applications: `${admin}/applications`,
  employee_dropdown: `${admin}/employee_dropdown`,
  emp_all_data: `${admin}/emp_all_data`,
  calendar_data: `${admin}/calendar_data`,
  moments: `${admin}/moments`,
  elibrary: `${admin}/elibrary`,
  emagazine: `${admin}/emagazine`,
  read_excel_year_list: `${admin}/read_excel_year_list`,
  announcement_data: `${admin}/announcement_data`,
  birthday: `${admin}/birthday`,
  get_pl: `${admin}/get_pl`,
  read_excel_cards: `${admin}/read_excel_cards`,
  e_magazine: `${admin}/e_magazine`,
  crop_health: `${admin}/crop_health`,
  banner: `${admin}/banner`,
  search_annoucement: `${admin}/search_annoucement`,
  read_excel1: `${admin}/read_excel1`,
  bi_report: `${admin}/bi_report`,
  dpt_repo: `${admin}/dpt_repo`,
  department: `${admin}/department`,
  department_repo: `${admin}/department_repo`,
  learning: `${admin}/learning`,
  other_documents: `${admin}/other_documents`,
  usersagar: `${admin}/user/check_fun`,
  waterMark: `${admin}/waterMark`,
  other_portal_login: `${admin}/other_portal_login`,
  user_details: `${admin}/user_details`,
  shared_document: `${admin}/shared_document`,
  signature_post: `${admin}/signature_post`,
  signature: `${admin}/signature`,
  test: `${admin}/test`,
  contacts: `${admin}/contacts`,
  domain_repo: `${admin}/domain_repo`,
  institutal_repo: `${admin}/institutal_repo`,
  Whistle_Blower: `${admin}/Whistle_Blower`,
  visiting_card: `${admin}/visiting_card`,
  identity_card: `${admin}/identity_card`,
  verify_otp: `${admin}/verify_otp`,
  resend_otp: `${admin}/resend_otp`,
  viewcomplaint: `${admin}/viewcomplaint`,
  viewcomplaintdata: `${admin}/viewcomplaintdata`,
  forward_record: `${admin}/forward_record`,
  send_complain: `${admin}/send_complain`,
  forwarded_list: `${admin}/forwarded_list`,
  url_permissions: `${admin}/url_permissions`,
  send_response: `${admin}/send_response`,
  search_complaint: `${admin}/search_complaint`,
  export_data: `${admin}/export_data`,
  departmental_library_upload: `${admin}/departmental_library_upload`,
  forward_email: `${admin}/forward_email`,
  HOD_approval: `${admin}/HOD_approval`,
  HOD_action: `${admin}/HOD_action`,
  csrf_token: `${admin}/csrf_token`,
};

export default Apiconfigs;
