import React, { useState, useRef, useEffect, useContext } from "react";
import {
  makeStyles,
  Box,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
  Dialog,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Profile_card from "./profile/Profile_card";
import { localeFunction } from "../utils";
import { AuthContext } from "../context/Auth";
import { useHistory } from "react-router-dom";
import { setSession } from "../context/Auth";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& button": {
      minWidth: "130px",
      color: "#fff",
    },
    "& h6": {
      fontSize: "12px",
      textAlign: "center",
      fontWeight: "600",
      color: "#fff",
      "@media(max-width:615px)": {
        color: "#000",
      },
    },
    "@media(max-width:615px)": {
      "& svg": {
        color: "#000",
      },
    },
  },

  profletext: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "pre",
    cursor: "pointer",
    color: "#FFF",
    "& h6": {
      textAlign: "left !important",
      fontSize: "15px",
      "@media(max-width:1024px)": {
        color: "#000",
      },
    },
    "& svg": {
      "@media(max-width:1024px)": {
        color: "#000",
      },
    },
  },
  paper: {
    minWidth: "500px",
    maxWidth: "500px",
    "@media(max-width:768px)": {
      minWidth: "400px",
      maxWidth: "410px",
    },
    "@media(max-width:375px)": {
      minWidth: "337px",
      maxWidth: "337px",
    },
  },
  userDegination: {
    textAlign: "left",
    "& h6": {
      textAlign: "left",
      lineHeight: "20px !important",
      "@media(max-width:1024px)": {
        color: "#000",
      },
      "@media(max-width:768px)": {
        whiteSpace: "normal",
      },
    },
  },
}));

export default function MenuListComposition() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [openDialog, setDialog] = useState(false);
  const prevOpen = useRef(open);
  const auth = useContext(AuthContext);
  const history = useHistory();

  const handleClickOpen = (val) => {
    setDialog(true);
  };
  const handleClosebtn = () => {
    setDialog(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleLogout = () => {
    setSession("");
    window.localStorage.removeItem("languageId");
    window.location.reload();
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Box
          width={"100%"}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          className={classes.profletext}
          onClick={handleToggle}
        >
          <Typography variant="h6">{auth?.profileData?.name}</Typography>
          {auth?.profileData === "" && (
            <Typography variant="h6" onClick={handleLogout}>
              {localeFunction("Logout")}
            </Typography>
          )}

          {auth?.profileData?.name && (
            <ExpandMoreIcon style={{ fontSize: "20px" }} />
          )}
        </Box>
        <Box className={classes.userDegination}>
          <Typography variant="h6">{auth?.profileData?.designation}</Typography>
        </Box>

        <Popper
          style={{ zIndex: "9999" }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper
                style={{ top: "21px", left: "-66px", position: "absolute" }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleClickOpen}>
                      {localeFunction("My_Profile")}
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      {localeFunction("Logout")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>

      <Dialog
        onClose={handleClosebtn}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        maxWidth="sm"
        fullWidth={true}
        classes={{ paper: classes.paper }}
      >
        <Profile_card
          handleClosebtn={handleClosebtn}
          profileData={auth?.profileData}
        />
      </Dialog>
    </Box>
  );
}
