import _ from "lodash";
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

import typography from "../theme/typography";

const baseOptions = {
  typography,

  overrides: {
    MuiTabs: {
      flexContainer: {
        display: "block",
        "@media(max-width:768px)": {
          overflowX: "scroll",
        },
      },
      root: {
        background: "#0192be21 !important",
        color: "#000",
      },
    },

    MuiTab: {
      root: {
        borderRadius: "0px",
        "&$selected": {},
      },
    },

    MuiContainer: {
      maxWidthLg: {
        maxWidth: "none !important",
        minWidth: "none !important",
      },
    },

    MuiButton: {
      outlinedPrimary: {
        color: "#0192be",
        borderColor: "#0192be",
        transition:
          "color 0.5s ease-in-out, borderColor 0.5s ease-in-out, background 0.5s ease-in-out",
        "&:hover": {
          color: "#fff",
          borderColor: "#0192be",
          backgroundColor: "#0192be !important",
        },
      },

      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
  },
};

const themesOptions = {};

export const createCustomTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
