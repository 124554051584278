// import { Contract } from "@ethersproject/contracts";

import axios from "axios";
import CryptoJS from "crypto-js";
import moment from "moment";
import { store } from "../Redux/store";
import { AuthContext } from "../context/Auth";

const base64Key = "w2IKah2csuNE77fv1Zd8+jzxcJ2GYh3WO5UZMqfHj3Y=";

export const WebsiteLinks =
  "http://sarus-elb-345957920.ap-south-1.elb.amazonaws.com:8080/";
export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 80)}...`;
  return sortAdd;
}
export const map = {
  // googleMapKey: "AIzaSyBpWyPnAG_3CFQC3NU0hJPt4r_IBhpsPEA",
  googleMapKey: "",
};

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

// export function getContract(address, ABI, library, account) {
//   return new Contract(address, ABI, getProviderOrSigner(library, account));
// }
export const decryptData = (encryptedData) => {
  const key = CryptoJS.enc.Base64.parse(base64Key);
  const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
  const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
  const ciphertext = CryptoJS.lib.WordArray.create(dataBytes.words.slice(4));
  const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
  const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
  return JSON.parse(decryptedText);
};
export const getAPIdata = async ({ endPoint }) => {
  try {
    let res;
    res = await axios({
      url: endPoint,
      method: "GET",
    })
      .then((res) => {
        if (res?.status === 200) {
          return decryptData(res?.data);
        }
        if (res?.data?.status === 400) {
          return false;
        }
        if (res?.data?.status === 401) {
          return false;
        }
        if (res?.data?.status === 205) {
          return false;
        }
      })
      .catch((error) => {
        return false;
        if (res?.data?.status === 400) {
          return false;
        }
        if (res?.data?.status === 401) {
          return false;
        }
        if (res?.data?.status === 205) {
          return false;
        }
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const putAPIdata = async ({ params, data, endPoint, headers }) => {
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "PUT",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          return false;
        }
        if (res?.data?.status === 401) {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const PostApiFunction = async ({ endPoint, data }) => {
  try {
    let res;
    res = await axios({
      url: endPoint,
      method: "POST",
      data: data,
    })
      .then((res) => {
        if (res?.status === 200) {
          return decryptData(res?.data);
        }
        if (res?.data?.status === 400) {
          return false;
        }
        if (res?.data?.status === 401) {
          return false;
        }
        if (res?.data?.status === 205) {
          return false;
        }
      })
      .catch((error) => {
        return false;
        if (res?.data?.status === 400) {
          return false;
        }
        if (res?.data?.status === 401) {
          return false;
        }
        if (res?.data?.status === 205) {
          return false;
        }
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const convertDateTime = (val) => {
  var tempDate = new Date(val);
  const toDateFormat = moment(tempDate).format("DD-MMM-yyyy hh:mm a");

  return toDateFormat;
};

export const localeFunction = (key) => {
  let str = store.getState("auth");
  let langCode = str.auth.langCode;
  switch (langCode) {
    case "en":
      return require(`../utils/lang/en`)[key];
    default:
      return require(`../utils/lang/${langCode}`)[key];
  }
};
