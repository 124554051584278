import React, { useState, useRef, useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Typography,
} from "@material-ui/core";
import MainAppbar from "../../../../view/pageskms/MainAppbar";
import Logo from "../../../../componentkms/Logo";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../context/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0,0,0,0.3) !important",
  },
  toolbar: {
    height: 80,
    backgroundColor: "rgba(0,0,0,0.3) !important",
    // backdropFilter: "blur(44px)",
    "& .MuiIconButton-root": {
      padding: "0px",
    },
  },

  handleHide: {
    display: "none",
  },
  handleShow: {
    display: "block",
  },
  divide: {
    height: "45px",
    margin: "0 32px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 10px",
    },
  },
  btn: {
    "& .MuiIconButton-root": {
      padding: "12px",
      [theme.breakpoints.down("xs")]: {
        padding: "4px",
      },
    },
  },
  text: {
    fontSize: "15px",
    lineHeight: "0px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  menuNavbar: {
    clipPath: "polygon(6% 0%, 100% 0%, 100% 700%, -35% 700%)",
    display: "flex",
    justifyContent: "start",
    paddingLeft: "50px",
    width: "83%",
    background: "#d6952c",
    // background: "rgb(255, 87, 51)",
    // background: "linear-gradient(90deg, #fcb444,#f5f08c,#bbddaa)",
    "@media(max-width:1440px)": {
      width: "78%",
    },
    "@media(max-width:1280px)": {
      width: "76%",
    },
  },
  mainLogo: {
    maxWidth: "125px",
    paddingTop: "7px",
    marginLeft: "18px",
    "@media(max-width:1024px)": {
      display: "none",
    },
  },
  comapnyBox: {
    // paddingLeft: "15px",
    // paddingTop: "10px",
    // width: "980px",
    "@media(max-width:1075px)": {
      display: "none",
    },
  },
  comapnyName: {
    "& h5": {
      color: "#000",
      fontSize: "11px",
      fontWeight: 500,
      width: "137px",
      lineHeight: "16px",
    },
    "& p": {
      color: "#000",

      fontSize: "12px",
      fontWeight: 400,
      // paddingBottom: "5px",
    },
  },

  mainTopbar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "66px",
    "@media(max-width:1024px)": {
      width: "initial",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData(props) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const auth = useContext(AuthContext);

  const descriptionElementRef = useRef(null);
  React.useEffect(() => {
    if (open1) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleHomeClick = () => {
    if (auth && auth.setSelectedMenu) {
      localStorage.removeItem("selectedMenu");
      auth.setSelectedMenu("");
    }

    history.push("/kms-portal");
  };
  return (
    <>
      <Box className={classes.mainTopbar}>
        <Box
          className={classes.mainLogo}
          // onClick={() => history.push("/kms-portal")}
          onClick={handleHomeClick}
          style={{ cursor: "pointer" }}
        >
          <Logo />
        </Box>
        <Box className={classes.comapnyBox}>
          <Box className={classes.comapnyName}>
            <Typography
              variant="body1"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
                paddingTop: "8px",
                lineHeight: "14px",
              }}
            >
              भारतीय कृषि बीमा कंपनी
              <span
                style={{
                  fontSize: "15px",
                  paddingTop: "3px",
                }}
              >
                ®
              </span>
            </Typography>
            <Typography variant="h5">
              Agriculture Insurance Company of India Limited
            </Typography>
          </Box>
        </Box>
        <Box className={classes.menuNavbar}>
          <MainAppbar />
        </Box>
      </Box>
    </>
  );
}
