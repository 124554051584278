import React, { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const theme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        "@media(max-width:615px)": {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      elevation8: {
        marginLeft: "-15px",
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: "2px solid #fff",
        },
      },
    },
    MuiCollapse: {
      entered: {
        // background: "#8c5f09",
      },
    },

    MuiAccordion: {
      root: {
        borderRadius: "0 !important",
        margin: "0px !important",
        "&.Mui-expanded": {
          // background: "#d6952c",
        },
        "@media(max-width:615px)": {
          color: "#fff",
          background: "transparent",
          margin: "5px 0 !important",
        },
      },
    },

    MuiAccordionSummary: {
      root: {
        borderRadius: "0",
      },
    },
    MuiAccordionDetails: {
      root: {
        borderRadius: "0",
        padding: "8px 0px 8px 10px",
        marginTop: "2px",
        "&.Mui-expanded": {
          background: "#8c5f09",
        },
      },
    },

    MuiSelect: {
      icon: {
        color: "#fff",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "16px",
    fontWeight: 500,
  },
  mainTitleBox: {
    display: "flex",
    gap: "15px",
  },
  TitleIcon: {
    minWidth: "30px",
    maxWidth: "30px",
    height: "30px",
    borderRadius: "50%",
    border: "1px solid #000",
    padding: "2px",
    "@media(max-width:615px)": {
      border: "1px solid #fff",
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "50%",
    },
  },
  accordionDetailsNotHover: {
    margin: "5px 20px 0px 17px",
    textAlign: "left",
    transition: "0.3s ease-in-out",
    backgroundColor: "#e4a70a96",
    color: "#000",
    borderRadius: "3px",
    marginBottom: "10px",
    cursor: "pointer",
    "& p": {
      fontSize: "14px !important",
    },
    "&:hover": {
      backgroundColor: "#d6952c",
      cursor: "pointer",
      color: "#fff",
    },
  },
  accordionDetails: {
    margin: "5px 20px 0px 17px",
    textAlign: "left",
    transition: "0.3s ease-in-out",
    borderRadius: "3px",
    marginBottom: "10px",
    "@media(max-width:615px)": {
      "& p": {
        color: "#fff !important",
      },
    },
    "&:hover": {
      backgroundColor: "#e4a70a96",
      cursor: "pointer",
      color: "#000",
    },
  },
  accordionDetailsSelected: {
    backgroundColor: "#d6952c",
    color: "#fff",
  },
  uploadbtn: {
    cursor: "pointer",
    paddingRight: "24px",
  },
  expandedAccordion: {
    zIndex: 999,
    margin: "0px !important",
  },
}));

export default function Sidebar({ setOpen, open }) {
  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const { profileData, setSelectedMenu, menuList, selectedMenu } =
    useContext(AuthContext);

  useEffect(() => {
    const savedSelectedMenu = localStorage.getItem("selectedMenu");

    if (savedSelectedMenu) {
      setSelectedMenu(savedSelectedMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    localStorage.setItem("expandedAccordion", isExpanded ? panel : "");
  };

  const clickHandle = () => {
    setOpen(false);
  };

  const handleSelectedMenu = (data, parentIndex) => {
    const menuKey = `${parentIndex}-${data.title}`;
    setSelectedMenu(menuKey);

    history.push({
      pathname: data?.path,
      state: data,
      siderTitle: menuList[parentIndex]?.title,
    });
    if (open) {
      clickHandle();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root}>
        {/* {menuList?.map((items, index) => {
          return (
            <Accordion
              key={index}
              elevation={0}
              style={{ background: "transparent !important" }}
              expanded={expanded === index}
              onChange={handleChange(index)}
              className={expanded === index ? classes.expandedAccordion : ""}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box className={classes.mainTitleBox}>
                  <Box className={classes.TitleIcon}>
                    <img src={items?.img} alt="icon Img" />
                  </Box>
                  <Typography className={classes.heading}>
                    {items?.title}
                  </Typography>
                </Box>
              </AccordionSummary>
              <Box align="end" className={classes.uploadbtn}>
                {items?.title === "Departmental Repository" ? (
                  <CloudUploadIcon onClick={() => history?.push("/upload")} />
                ) : (
                  ""
                )}
              </Box>
              {items?.subItems?.map((data, subIndex) => {
                // const isClickable = profileData?.department_name == data?.title
                // console.log("isClickable=>",isClickable);

                return (
                  <AccordionDetails
                    key={subIndex}
                    className={`${classes.accordionDetailsNotHover} ${
                      selectedMenu === `${index}-${data.title}`
                        ? classes.accordionDetailsSelected
                        : ""
                    }`}
                    onClick={() => handleSelectedMenu(data, index)}
                    // onClick={isClickable ? () => handleSelectedMenu(data, index) : null}
                  >
                    <Typography>{data?.title}</Typography>
                  </AccordionDetails>
                );
              })}
            </Accordion>
          );
        })} */}

        {menuList?.map((items, index) => {
          return (
            <Accordion
              key={index}
              elevation={0}
              style={{ background: "transparent !important" }}
              expanded={expanded === index}
              onChange={handleChange(index)}
              className={expanded === index ? classes.expandedAccordion : ""}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box className={classes.mainTitleBox}>
                  <Box className={classes.TitleIcon}>
                    <img src={items?.img} alt="icon Img" />
                  </Box>
                  <Typography className={classes.heading}>
                    {items?.title}
                  </Typography>
                </Box>
              </AccordionSummary>

              {/* {items?.title === "Departmental Repository" && (
                <Box align="end" className={classes.uploadbtn}>
                  <CloudUploadIcon onClick={() => history?.push("/upload")} />
                </Box>
              )} */}

              {items?.subItems?.map((data, subIndex) => {
                // const isMatchedDepartment =
                //   profileData?.department_name === data?.title;

                // if (
                //   items?.title === "Departmental Repository" &&
                //   !isMatchedDepartment
                // ) {
                //   return null;
                // }

                return (
                  <AccordionDetails
                    key={subIndex}
                    className={`${classes.accordionDetailsNotHover} ${
                      selectedMenu === `${index}-${data.title}`
                        ? classes.accordionDetailsSelected
                        : ""
                    }`}
                    onClick={() => handleSelectedMenu(data, index)}
                  >
                    <Typography>{data?.title}</Typography>
                  </AccordionDetails>
                );
              })}
            </Accordion>
          );
        })}
      </Box>
    </ThemeProvider>
  );
}
