import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopSwapperDialog from "../../../component/TopSwapperDialog";
import { localeFunction, PostApiFunction } from "../../../utils";
import { changeDashboard } from "../../../Redux/Auth/action";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./menucss.css";
import Apiconfigs from "../../../ApiConfig/Apiconfig";
import { AuthContext } from "../../../context/Auth";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  topbarContaier: {
    display: "flex",
    alignItems: "center",
    background: "#0192be",
    height: "55px",
    paddingLeft: "24px",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    "@media(max-width:615px)": {
      background: "none",
      paddingLeft: "initial",
      display: "initial",
    },
  },
  mainIndexsection: {
    "& .menulist": {
      "& :hover": {
        cursor: "pointer",
      },
      "& h6": {
        fontSize: "16px",
        fontWeight: 500,
        color: "#FFF",
        textTransform: "captalize",
        whiteSpace: "pre",
        "@media(max-width:615px)": {
          fontSize: "12px",
        },
        "&:hover": {
          transition: "0.5s ease-in-out",
          // color: "rgb(6, 72, 91)",
        },
      },
    },
    "& .menusection": {
      "& .MenuTab": {
        position: "relative",
        marginLeft: "35px",

        "@media(max-width:615px)": {
          marginLeft: "5px",
        },
        "& h6": {
          fontSize: "16px",
          fontWeight: 500,
          color: "#FFF",
          textTransform: "captalize",
          cursor: "pointer",
          "@media(max-width:615px)": {
            fontSize: "12px",
            lineHeight: "25px",
          },
        },
        "& a": {
          textDecoration: "none",
          "&:hover": {},
        },
        "& .menuComponent": {
          display: "none",
          zIndex: 1,
          transition: "0.5s ease-in-out",
        },
        "&:hover": {
          "& .menuComponent": {
            display: "block",
            position: "absolute",
            zIndex: 999,
            background: "#fff",
            width: "250px !important",
            "@media(max-width:425px)": {
              position: "fixed",
              zIndex: 999,
            },
          },
        },
      },
    },
  },

  commonMenues: {
    display: "flex",
    alignItems: "center",
    "@media(max-width:615px)": {
      padding: "10px",
      borderBottom: "1px solid #ffff",
      "& svg": {
        display: "none",
      },
    },
  },

  kmsBox: {
    background: "#06485b",
    padding: "5px",
    borderRadius: "5px",
    color: "#FFF !important",
    cursor: "pointer",
    "@media(max-width:615px)": {
      background: "none",
      padding: "0px",
    },
  },
  commonMenuesScreen: {
    display: "block",
    "@media(max-width:615px)": {
      display: "none",
    },
  },
  commonMenuesScreen1: {
    display: "none",
    "&:hover": {
      transition: "0.5s ease-in-out",
      color: "rgb(6, 72, 91)",
    },
    "@media(max-width:615px)": {
      display: "block",
    },
  },
  complainAccor: {
    "@media(max-width:615px)": {
      display: "block",
      "& h5": {
        borderBottom: "1px solid #fff",
        padding: "10px 5px",
      },
    },
  },
}));
const theme = createTheme({
  overrides: {
    MuiCollapse: {
      wrapperInner: {
        // marginTop: "-30px",
      },
    },
    MuiAccordion: {
      root: {
        "&.Mui-expanded": {
          margin: "0",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        // display: "initial",
        "& h5": {
          padding: "10px",
          color: "#fff",
          fontSize: "14px",
          background: "#23262f",
          width: "100%",
          // margin: "10px",
        },
      },
    },
  },
});
const Top_navbar = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openSwappeer, setOpenSwapper] = useState(false);
  const { profileData } = useContext(AuthContext);
  const [openSitemap, setSitemap] = useState("");

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [_kmsSelect, setKmsSelect] = useState(
    window.localStorage.getItem("selectSite") != null
      ? window.localStorage.getItem("selectSite")
      : "false"
  );

  const employee_id = profileData?.employee_id;
  const employee_email = profileData?.user_email;
  const [page, setPage] = useState(1);

  const handleClick = (value) => {
    history.push("/kms-portal");
    window.location.reload();

    setKmsSelect("true");
    dispatch(changeDashboard({ changeDashboard: "true" }));
    window.localStorage.setItem("selectSite", "true");
  };

  const swapperOpen = (sitemap) => {
    setOpenSwapper(true);
    setSitemap(sitemap);
  };

  const swapperClose = () => {
    setOpenSwapper(false);
  };

  const ViewComplaint = async () => {
    try {
      const res = await PostApiFunction({
        endPoint: Apiconfigs.url_permissions,
        data: {
          email: employee_email,
        },
      });

      if (res?.parmission_name === "viewComplaint") {
        window.sessionStorage.setItem("viewComplaint", "viewComplaint");
        history.push("/complain-table");
      } else if (res?.parmission_name === "gmViewComplaint") {
        window.sessionStorage.setItem("gmViewComplaint", "gmViewComplaint");
        window.sessionStorage.setItem("viewComplaint", "");
        history.push("/forward-complain-table");
      } else {
        swal({
          title: res?.parmission_name,
          icon: "warning",
          buttons: {
            custom: {
              text: "OK",
              value: "okBtn",
              className: "btn btn-primary",
              visible: true,
              closeModal: true,
            },
          },
          closeOnClickOutside: false,
        }).then((value) => {
          if (value === "okBtn") {
            history?.push("/internal-portal");
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const serachCompaintApi = async () => {
    try {
      const res = await PostApiFunction({
        endPoint: Apiconfigs.search_complaint + `/?page=${page}`,
        data: {
          user_id: employee_id,
          status: "Pending",
        },
      });

      if (res?.status === 401) {
        swal({
          title: res?.msg,

          icon: "warning",
          buttons: {
            custom: {
              text: "OK",
              value: "okBtn",
              className: "btn btn-primary",
              visible: true,
              closeModal: true,
            },
          },
          closeOnClickOutside: false,
        }).then((value) => {
          if (value === "okBtn") {
            history?.push("/internal-portal");
          }
        });
      } else {
        history?.push("/search-complaint");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("selectSite") == null) {
      dispatch(
        changeDashboard({
          changeDashboard: window.localStorage.getItem("selectSite")
            ? window.localStorage.getItem("selectSite")
            : "false",
        })
      );
    } else {
      dispatch(
        changeDashboard({
          changeDashboard: window.localStorage.getItem("selectSite"),
        })
      );
    }
  }, [JSON.parse(window.localStorage.getItem("selectSite")) == null]);

  return (
    <Box>
      <Box className={classes.mainIndexsection}>
        <Box className="menusection">
          <Box className={classes.topbarContaier}>
            <Box className={classes.commonMenues}>
              <Box className="MenuTab">
                <a href="https://www.aicofindia.com/" target="_blank">
                  <Box className="menulist">
                    <Typography variant="h6">
                      {localeFunction("External_Portal")}
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Box>
            <Box className={classes.commonMenues}>
              <Box className="MenuTab">
                {" "}
                <Box className={classes.kmsBox}>
                  <Typography
                    variant="h6"
                    onClick={handleClick}
                    style={{ color: "#FFF" }}
                  >
                    {localeFunction("Kms_Portal")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.commonMenuesScreen}>
              <Box className="MenuTab">
                <div class="dropdown">
                  <Typography variant="h6" class="dropbtn">
                    {localeFunction("Whistle_Blower_Complaint")}
                  </Typography>

                  <div class="dropdown-content">
                    {profileData?.register_complaint && (
                      <Typography
                        variant="h5"
                        onClick={() => {
                          if (profileData?.mobile != null) {
                            history.push("/whistle-blower-complaint-form");
                          } else {
                            alert(localeFunction("Mobile_number_is"));
                          }
                        }}
                      >
                        {localeFunction("Register_Complaint")}
                      </Typography>
                    )}

                    {profileData?.view_compaint && (
                      <Typography variant="h5" onClick={ViewComplaint}>
                        {localeFunction("View_Complaint")}
                      </Typography>
                    )}

                    {profileData?.gm_view_complaint && (
                      <Typography variant="h5" onClick={ViewComplaint}>
                        {localeFunction("View_Complaint")}
                      </Typography>
                    )}

                    {profileData?.search_compaint && (
                      <Typography
                        variant="h5"
                        // onClick={() => history?.push("/search-complaint")}
                        onClick={serachCompaintApi}
                      >
                        {localeFunction("Complaint_Status")}
                      </Typography>
                    )}
                  </div>
                </div>
              </Box>
            </Box>
            <ThemeProvider theme={theme}>
              <Accordion
                elevation={0}
                style={{ background: "#08090b " }}
                expanded={expanded == "panel1"}
                onChange={handleChange("panel1")}
                className={classes.commonMenuesScreen1}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ color: "#fff", display: "block" }}
                    />
                  }
                >
                  <Box className="menulist">
                    <Typography variant="h6" className={classes.whistleComtext}>
                      {localeFunction("Whistle_Blower_Complaint")}
                    </Typography>
                  </Box>
                </AccordionSummary>

                <AccordionDetails className={classes.complainAccor}>
                  {profileData?.register_complaint && (
                    <Typography
                      variant="h5"
                      onClick={() => {
                        if (profileData?.mobile != null) {
                          history.push("/whistle-blower-complaint-form");
                        } else {
                          alert(localeFunction("Mobile_number_is"));
                        }
                      }}
                    >
                      {localeFunction("Register_Complaint")}
                    </Typography>
                  )}
                  {profileData?.view_compaint && (
                    <Typography variant="h5" onClick={ViewComplaint}>
                      {localeFunction("View_Complaint")}
                    </Typography>
                  )}
                  {profileData?.gm_view_complaint && (
                    <Typography variant="h5" onClick={ViewComplaint}>
                      {localeFunction("View_Complaint")}
                    </Typography>
                  )}
                  {profileData?.search_compaint && (
                    <Typography
                      variant="h5"
                      // onClick={() => history?.push("/search-complaint")}
                      onClick={serachCompaintApi}
                    >
                      {localeFunction("Complaint_Status")}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>

      {openSitemap === "sitemap" ? (
        <TopSwapperDialog
          swapperOpen={swapperOpen}
          swapperClose={swapperClose}
          openSwappeer={openSwappeer}
          type={openSitemap}
        />
      ) : (
        <TopSwapperDialog
          swapperOpen={swapperOpen}
          swapperClose={swapperClose}
          openSwappeer={openSwappeer}
          type="CropHealthBulletin"
        />
      )}
    </Box>
  );
};

export default Top_navbar;
