import React from "react";
import { makeStyles, Box, IconButton, Divider } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CloseIcon from "@material-ui/icons/Close";
import ProfileIamge from "./ProfileIamge";
import Logo from "../component/Logo";
import { NavLink, useHistory } from "react-router-dom";
import Navbar from "../view/pages/home/Top_navbar";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 250,
    background: "#08090b",
  },
  mainMobileDrawer: {
    zIndex: 99999,
  },
  drawerrHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    backgroundColor: "#FFF",
    margin: "10px 0  10px 0",
  },
}));

const MobileDrawer = ({ open, closeFunction }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainMobileDrawer}>
      <SwipeableDrawer
        classes={{
          paper: classes.paper,
        }}
        anchor="left"
        {...{
          anchor: "left",
          open: open,
          onClose: closeFunction,
        }}
      >
        <Box className={classes.drawerContent}>
          <ProfileIamge />
        </Box>
        <Navbar />
      </SwipeableDrawer>
    </Box>
  );
};

export default MobileDrawer;
