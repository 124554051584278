// import React from "react";
// import PropTypes from "prop-types";
// import { makeStyles, Box } from "@material-ui/core";
// import TopBar from "./NavBar/index";
// import TopBar1 from "./TopBar";
// import Footer from "../../../view/pageskms/Home/Footer1";
// import Banner from "../../../view/pageskms/banner/Banner";

// const useStyles = makeStyles((theme) => ({
//   content: {
//     display: "flex",
//     justifyContent: "flex-end",
//     alignItems: "center",
//   },
//   dashboardCenterContant: {
//     width: "calc(100% - 330px)",
//     marginTop: "109px",
//     marginLeft: "330px",
//     overflowX: "hidden",
//     height: "calc(100vh - 109px)",
//     "@media (max-width: 1480px)": {
//       width: "calc(100% - 303px)",
//       overflowX: "hidden",
//       overflowY: "scroll",
//     },
//     "@media (max-width: 1280px)": {
//       width: "calc(100% - 305px)",
//       overflowX: "hidden",
//       overflowY: "scroll",
//       marginLeft: "310px",
//     },

//     "@media(max-width:1024px)": {
//       width: "100%",
//       height: "calc(100vh - 109px)",
//       marginLeft: "initial",
//     },
//     "@media(max-width:599px)": {
//       marginTop: "105px",
//       marginLeft: "initial",
//     },
//     "@media(max-width:425px)": {
//       marginTop: "100px",
//       marginLeft: "initial",
//     },
//   },
//   RightContent: {
//     display: "block",
//     overflowX: "hidden",
//   },
//   childerenBox: {
//     padding: "0px 10px 20px 10px",
//   },
// }));

// const DashboardLayout = ({ children }) => {
//   const classes = useStyles();
//   return (
//     <>
//       <Box className={classes.root}>
//         <TopBar1 />
//         <Box className={classes.RightContent}>
//           <TopBar />

//           <Box className={classes.dashboardCenterContant}>
//             <Box>
//               <Banner />
//             </Box>
//             <Box className={classes.childerenBox}>{children}</Box>
//           </Box>
//           <Footer />
//         </Box>
//       </Box>
//     </>
//   );
// };

// DashboardLayout.propTypes = {
//   children: PropTypes.node,
// };

// export default DashboardLayout;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Box } from "@material-ui/core";
import TopBar from "./NavBar/index";
import TopBar1 from "./TopBar";
import Footer from "../../../view/pageskms/Home/Footer1";
import Banner from "../../../view/pageskms/banner/Banner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  dashboardCenterContant: {
    // flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
    marginLeft: "330px",
    marginTop: "109px",
    "@media (max-width: 1480px)": {
      marginLeft: "300px",
    },
    "@media (max-width: 1280px)": {
      marginLeft: "300px",
    },
    "@media(max-width:1024px)": {
      width: "100%",

      marginLeft: "initial",
    },
    "@media(max-width:599px)": {
      marginTop: "105px",
      marginLeft: "initial",
    },
    "@media(max-width:425px)": {
      marginTop: "100px",
      marginLeft: "initial",
    },
  },
  childerenBox: {
    width: "100%",
    padding: "0px 12px 20px 20px",
  },
  footer: {
    marginTop: "auto", // Pushes the footer to the bottom
    backgroundColor: theme.palette.background.paper,
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <TopBar1 />
      <Box className={classes.content}>
        <TopBar />
        <Box className={classes.dashboardCenterContant}>
          <Banner />
          <Box className={classes.childerenBox}>{children}</Box>
        </Box>
      </Box>
      <Footer className={classes.footer} />
    </Box>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
